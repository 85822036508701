import React, {
	useEffect,
	useState
} from "react";
import ReturnViewButton from "./ReturnViewButton";
import {
	getDisplayDate,
	getCurrentDateTruncated
} from "../../utils/date.js";
import * as tableConstants from "../Shared/Config/TableConfig";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Pagination
} from "@mui/material";
import { getPageValue } from "../../utils/pagination";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import { DataContext } from "../../App";
import SellerDependentView from "../Shared/SellerDependentView";
import { LoadingStatusTable } from "../Shared/LoadingStatus/LoadingStatus";
import PageRowsDropDown from "../Shared/PageRows/PageRows";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";

export default function ReturnView({ seller }) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 365);

	const [state, setState] = useState({
		isLoading: false,
		filter: "",
		startDate: initStartDate,
		endDate: initEndDate,
		resultsData: [],
		resultsTotalCount: 0,
		summaryData: [],
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0
	});

	const getCarrierLabelFromName = (name) => {
		switch (name) {
		case "inpost_gb": return "InPost";
		case "hermes_c2c_gb": return "Evri ParcelShop";
		case "hermes_gb": return "Evri ParcelShop";
		default: return name;
		}
	};

	useEffect(() => {

		if (!seller) return;

		const getSummaryData = () => {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			// Handle date picker inclusivity to end of day by adding a whole
			// day and subtracting one time unit
			let requestEndDate = new Date(state.endDate);
			requestEndDate.setDate(requestEndDate.getDate() + 1);
			requestEndDate = new Date(requestEndDate - 1);

			context.dataProvider.getSellerReturns(seller, state.startDate, requestEndDate, state.paginationCurrentPage, state.paginationPostsPerPage, state.filter)
				.then(res => {
					setState(previousState => {
						return {
							...previousState,
							resultsData: res.data,
							summaryData: res.data.sort((a, b) => a.last_updated > b.last_updated ? -1 : 1),
							resultsTotalCount: res.data.length,
							paginationPages: Math.ceil(Number(res.data.length) / Number(state.paginationPostsPerPage)),
							isLoading: false,
						};
					});
				});
		};

		getSummaryData();
	}, [seller, state.paginationCurrentPage, state.startDate, 
		state.endDate, state.paginationPostsPerPage, state.filter, context.dataProvider]);

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue,
				paginationCurrentPage: 1
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue,
				paginationCurrentPage: 1,
			};
		});
	};

	const SummaryTableRows = () => {

		if (state.isLoading) {
			return (
				<LoadingStatusTable message="Loading ..." colSpan={7} />
			);
		}

		if (typeof state.resultsData === "object" && state.resultsData.length > 0) {
			return (
				state.resultsData.sort((b, a) => a.last_updated < b.last_updated ? -1 : 1).map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.bezos_system_id ? item.bezos_system_id : "-"}</TableCell>
						<TableCell>{item.order_number}</TableCell>
						<TableCell>
							<StatusBox 
								status={item.status} 
								statusType="order"
							/>
						</TableCell>
						<TableCell>{getCarrierLabelFromName(item.carrier)}</TableCell>
						<TableCell>
							{item.tracking_url !== "" ?
								<a className="external-link" href={item.tracking_url} target="_blank" rel="noopener noreferrer">{item.tracking_number}</a>
								:
								item.tracking_number
							}
						</TableCell>
						<TableCell>{item.last_updated !== "" ? getDisplayDate(item.last_updated) : ""}</TableCell>
						<TableCell align="right">
							<ReturnViewButton
								return_id={item.id}
								seller={item.seller}
								seller_id={seller}
							/>
						</TableCell>
					</TableRow>
				));
		} else {
			return (
				<TableRow>
					<TableCell colSpan={7} align="center">
						<Typography>No data found</Typography>
					</TableCell>
				</TableRow>
			);
		}
	};

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
				paginationCurrentPage: 1,
			};
		});
	};

	return (
		<Box id="returns-status">
			<SellerDependentView seller={seller}>
				<Box>
					<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
						<Typography variant="h5" sx={{ marginBottom: 1 }}>
							Returns <RecordsTotal total={state.resultsTotalCount || 0} />
						</Typography>
						<Grid item container className="form-inputs">
							<Grid item>
								<SearchFilter
									filter={state.filter} 
									onFilterChange={handleFilterChange}
									label={"Filter By Order Number"}
								/>
							</Grid>
							<Grid item xs />
							<Grid item>
								<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
								<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
							</Grid>
						</Grid>
						<TableContainer sx={{ maxHeight: 600, overflow: "auto" }} component={Paper}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Return ID</TableCell>
										<TableCell>Order Number</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Carrier</TableCell>
										<TableCell>Tracking Number</TableCell>
										<TableCell>Last Updated</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<SummaryTableRows />
								</TableBody>
							</Table>
						</TableContainer>
						<Grid
							container
							padding={2}
							className="table-footer"
						>
							{paginationView}
							<PageRowsDropDown
								resultsTotalCount={state.resultsTotalCount}
								paginationPostsPerPage={state.paginationPostsPerPage}
								pageRowOptions={pageRowOptions}
								onPageRowChange={onPageRowChange}
								seller={seller}
							/>
						</Grid>
					</Paper>
				</Box>
			</SellerDependentView>
		</Box>
	);
}
