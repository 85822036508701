import React, {
	useEffect,
	useState,
} from "react";
import SellerDependentView from "../Shared/SellerDependentView";
import PermissionDependentView from "../Shared/PermissionDependentView/PermissionDependentView";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Pagination,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import { DataContext } from "../../App";
import ClaimsTable from "./components/ClaimsTable";
import * as tableConstants from "../Shared/Config/TableConfig";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { getPageValue } from "../../utils/pagination";
import PageRowsDropDown from "../Shared/PageRows/PageRows";
import { 
	getCurrentDateTruncated,
	getFormattedTime,
} from "../../utils/date.js";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import SearchFilter from "../Shared/SearchFilter/SearchFilter";

export default function Claims({ seller, userdata }) {

	const context = React.useContext(DataContext);
	const pageRowOptions = tableConstants.PAGINATION_OPTIONS;
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 365);

	const [state, setState] = useState({
		isLoading: false,
		filter: "",
		startDate: initStartDate,
		endDate: initEndDate,		
		claimStatus: "",
		resultsData: [],
		resultsTotalCount: 0,
		paginationCurrentPage: 1,
		paginationPostsPerPage: pageRowOptions[0],
		paginationPages: 0
	});

	useEffect(() => {

		if (!seller) return;

		const getSummaryData = () => {

			setState(previousState => {
				return {
					...previousState,
					isLoading: true,
				};
			});

			const params = new URLSearchParams({
				since: getFormattedTime(state.startDate),
				to: getFormattedTime(state.endDate),
				sellers: seller,
				status: state.claimStatus,
				filter: state.filter,
				page: state.paginationCurrentPage,
				results: state.paginationPostsPerPage
			});

			context.dataProvider.getClaimsSummary(seller, params)
				.then(res => {
					if (!res) return;
					setState(previousState => {
						return {
							...previousState,
							resultsData: res.data.order_claims.sort((a, b) => a.order_number > b.order_number ? -1 : 1),
							resultsTotalCount: res.data.total,
							paginationPages: Math.ceil(Number(res.data.total) / Number(state.paginationPostsPerPage)),
							isLoading: false,
						};
					});
				});
		};

		getSummaryData();
	}, [seller, state.startDate, state.endDate, state.claimStatus, state.filter, state.paginationCurrentPage, state.paginationPostsPerPage, context.dataProvider]);

	let paginationView;
	if (state.resultsTotalCount <= state.paginationPostsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={state.paginationPages}
				page={state.paginationCurrentPage}
				color="primary"
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							paginationCurrentPage: getPageValue(input, state.paginationCurrentPage),
						};
					});
				}}
			/>
		);
	}

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue,
				paginationCurrentPage: 1
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue,
				paginationCurrentPage: 1,
			};
		});
	};

	const onClaimStatusChange = (e) => {
		setState((previousState) => {
			return {
				...previousState,
				claimStatus: e.target.value,
				paginationCurrentPage: 1,
			};
		});
	};

	const onPageRowChange = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				paginationPostsPerPage: pageRows,
				paginationCurrentPage: 1,
			};
		});
	};

	const handleFilterChange = (value) => {
		setState(previousState => {
			return {
				...previousState,
				filter: value,
				paginationCurrentPage: 1,
			};
		});
	};
	
	const StatusFilter = () => {

		const data = () => {
			const data = ["In Progress", "Successful", "Unsuccessful"];
			return data.map((name, index) => ({
				id: index + 1,
				name: name,
			}));
		};

		return (
			<FormControl sx={{ minWidth: 150 }} size="small">
				<InputLabel id="claim_status">Claim Status</InputLabel>
				<Select
					labelId="claim_status"
					value={state.claimStatus}
					label="Claim Status"
					onChange={(e) => onClaimStatusChange(e)}
				>
					{data().map(({ id, name }) => (
						<MenuItem key={id} value={name.toLowerCase()}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12}>
				<Typography variant="h5" sx={{ marginBottom: 1 }}>
					Claims <RecordsTotal total={state.resultsTotalCount} />
				</Typography>
				<Grid item container className="form-inputs">
					<Grid item>
						<SearchFilter
							filter={state.filter} 
							onFilterChange={handleFilterChange}
							label={"Search"}
						/>
					</Grid>
					<Grid item xs />
					<Grid item mb={2}>
						<StatusFilter/>
					</Grid>
					<Grid item xs />
					<Grid item mb={2}>
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>				
			</Grid>
		);
	};

	return (
		<Box id="claims">
			<PermissionDependentView userdata={userdata} permission="raise_claim">
				<SellerDependentView seller={seller}>
					<Grid container>
						<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
							<Grid item xs={12}>
								<PageHeading />
								<ClaimsTable
									tableData={state.resultsData}
									isLoading={state.isLoading}
								/>
								<Grid
									container
									padding={2}
									className="table-footer"
								>
									{paginationView}
									<PageRowsDropDown
										resultsTotalCount={state.resultsTotalCount}
										paginationPostsPerPage={state.paginationPostsPerPage}
										pageRowOptions={pageRowOptions}
										onPageRowChange={onPageRowChange}
										seller={seller}
									/>
								</Grid>	
							</Grid>
						</Paper>
					</Grid>
				</SellerDependentView>
			</PermissionDependentView>
		</Box>
	);
}