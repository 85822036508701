import React, {
	useEffect,
	useState,
	useCallback
} from "react";
import {
	Button,
	Grid,
	Paper,
	Table,
	TextField,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Box,
	LinearProgress,
	Dialog,
	Stack,
	Snackbar,
	Switch,
	FormControlLabel
} from "@mui/material";
import "./AddProducts.scss";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import UseFetchData from "../../Shared/FetchData/FetchData";
import ImportItems from "../../GoodsInCreate/components/ImportItems";
import { DataContext } from "../../../App";
import { stringHash } from "../../../utils/string";
import { AlertBox } from "../../Shared/Alert/Alert";
import { sanitizeFilterInput } from "../../../utils/text";
import { RecordsTotal } from "../../Shared/ResultsFound/ResultsFound";
import SearchFilter from "../../Shared/SearchFilter/SearchFilter";

export default function AddProducts({
	sellerID,
	warehouseID,
	cartType,
	onAddProductsFormSubmit,
	onPanelsVisibleChange,
	...props
}) {

	const context = React.useContext(DataContext);
	const productStatuses = UseFetchData(useCallback(() => context.dataProvider.getProductStatuses(), [context.dataProvider]));
	const inventory = UseFetchData(useCallback(() => context.dataProvider.getStock(sellerID, warehouseID, false, 0), [context.dataProvider, sellerID, warehouseID, productStatuses.results]));

	const page_data = {
		inventory: {
			resultsData: inventory.results,
			isLoading: inventory.isLoading,
			status: inventory.status,
		},
	};

	const [state, setState] = useState({
		isLoading: page_data.inventory.isLoading,
		filter: "",
		inventoryData: page_data.inventory.resultsData.sort((b, a) => a.sku > b.sku ? -1 : 1),
		inputQuantities: new Map(),
		responseAlert: "",
		isItemInCart: false,
		productImage: "",
		isVisibleImportASNDiv: false,
		asnImportData: [],
		isASNImportDataValid: false,
		isSubmitDisabled: false,
	});

	const onASNImportData = (array) => {
		setState(previousState => {
			return {
				...previousState,
				asnImportData: array,
			};
		});
	};

	const onASNImportStatus = (status, message) => {
		if (status === false) {
			setState(previousState => {
				return {
					...previousState,
					isASNImportDataValid: false,
					isAlertVisible: true,
					alertSeverity: "error",
					alertMessage: message,
				};
			});
		} else {
			setState(previousState => {
				return {
					...previousState,
					isASNImportDataValid: true,
					isAlertVisible: true,
					alertSeverity: "success",
					alertMessage: "File imported successfully.",
				};
			});
		}

	};

	const [cart, setCart] = useState({});
	const [cartStatus, setCartStatus] = useState("Cart Empty");

	if (props.setItems) {
		props.setItems(cart);
	}

	const itemAddedToCart = (sku) => {
		if (cart.length === 0) return;

		if (cart.some(obj => obj.sku === sku)) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		const responseDataCheck = () => {
			if (warehouseID === null || warehouseID === undefined || warehouseID === "") {
				setState(previousState => {
					return {
						...previousState,
						responseAlert: "Please select a warehouse before selecting products",
					};
				});
			} else if (page_data.inventory.status === false) {
				setState(previousState => {
					return {
						...previousState,
						responseAlert: "An error occurred while trying to get stock for this warehouse. "
							+ "Please try again and contact support if the problem continues.",
					};
				});
			}
		};
		responseDataCheck();
	}, [page_data.inventory.status, warehouseID]);

	useEffect(() => {
		const search = () => {
			const inputWords =  sanitizeFilterInput(state.filter);
			const visible = page_data.inventory.resultsData.filter(item => {
				return inputWords.every(word =>
					item.name.toLowerCase().includes(word) ||
					item.sku.toLowerCase().includes(word)
				);
			});

			setState(previousState => {
				return {
					...previousState,
					inventoryData: visible,
				};
			});
		};
		search();
	}, [state.filter, page_data.inventory.resultsData]);


	const onClickToggleImportItemsDiv = () => {
		setState(previousState => {
			return {
				...previousState,
				isVisibleImportASNDiv: !previousState.isVisibleImportASNDiv
			};
		});
	};

	useEffect(() => {
		if (state.isASNImportDataValid === true) {
			setCart(state.asnImportData);
		} else if (props.initialCart != null) {
			setCart(props.initialCart);
		} else {
			setCart([]);
		}
	}, [state.asnImportData, props.initialCart, state.isASNImportDataValid, warehouseID]);

	const onAddItem = (sku) => {
		if (cart.some(obj => obj.sku === sku)) {
			return;
		}

		const matchedInventoryItem = page_data.inventory.resultsData.find(obj => obj.sku === sku);
		matchedInventoryItem.quantity = 1;
		setCart(arr => [...arr, matchedInventoryItem]);

		setState(previousState => {
			return {
				...previousState,
				isItemInCart: true
			};
		});

	};

	const onQuantityChange = (e, itemID) => {
		const qtys = state.inputQuantities;
		qtys.set(itemID, e.target.value);

		setState(previousState => {
			return {
				...previousState,
				inputQuantities: qtys,
			};
		});

		let matchedCartItem = cart.find(obj => obj.order_item_id === itemID);
		if (!matchedCartItem) {
			matchedCartItem = cart.find(obj => obj.sku === itemID);
		}

		const hash = stringHash(itemID);
		matchedCartItem.quantity = getSkuQtyFromCartTable(hash);
	};

	const getSkuQtyFromCartTable = (props) => {
		if (document.querySelector(`#skuQTY-${props}`).value !== null) {
			const skuQty = document.querySelector(`#skuQTY-${props}`).value;
			return (
				Number(skuQty)
			);
		} else {
			return (
				0
			);
		}
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setState(previousState => {
			return {
				...previousState,
				isAlertVisible: false
			};
		});
	};

	const SnackBarComponent = () => {
		return (
			<Stack spacing={3}>
				<Snackbar
					open={state.isAlertVisible}
					autoHideDuration={6000}
					onClose={handleCloseAlert}
				>
					<Box>
						<AlertBox
							onClose={handleCloseAlert}
							severity={state.alertSeverity}
							message={state.alertMessage}
						/>
					</Box>
				</Snackbar>
			</Stack>
		);
	};


	const handleBackButton = () => {
		onPanelsVisibleChange({
			isShippingDetailsPanelVisible: true,
			isAddProductsPanelVisible: false,
			isAdditionalDetailsPanelVisible: false,
		});
	};

	const updateCartItemsWithProductID = (obj) => {
		if (obj.length === 0 || page_data.inventory.resultsData.length === 0) return false;
	
		const validItems = [];
		const missingSkus = [];
	
		obj.forEach((object) => {
			const copyKeyFromInventory = page_data.inventory.resultsData.find(inventoryItem => inventoryItem.sku === object.sku);
	
			if (!copyKeyFromInventory) {
				missingSkus.push(object.sku);
			} else {
				object.product_id = copyKeyFromInventory.product_id;
				validItems.push(object);
			}
		});
	
		if (missingSkus.length > 0) {
			setState(previousState => ({
				...previousState,
				isAlertVisible: true,
				alertSeverity: "error",
				alertMessage: `The following SKU(s) "${missingSkus.join(", ")}" were not found in your inventory and therefore removed from your cart.`,
			}));
		}
	
		obj.length = 0;
		obj.push(...validItems);
	
		return missingSkus.length > 0;
	};

	useEffect(() => {
		if (cart.length === 0 || page_data.inventory.resultsData.length === 0) {
			setState(previousState => {
				return {
					...previousState,
					isSubmitDisabled: true
				};
			});
		} else {
			setState(previousState => {
				return {
					...previousState,
					isSubmitDisabled: false
				};
			});
		}
	}, [cart, page_data.inventory.resultsData]);

	const handleSubmit = () => {
		if (cart.length > 0) {

			const hasErrors = updateCartItemsWithProductID(cart);
			if (hasErrors) return;

			onAddProductsFormSubmit(cart);
			onPanelsVisibleChange({
				isShippingDetailsPanelVisible: false,
				isAddProductsPanelVisible: false,
				isAdditionalDetailsPanelVisible: true,
			});
			setState(previousState => {
				return {
					...previousState,
					isSubmitDisabled: false
				};
			});
		} else {
			setCartStatus("Please add items to the Cart to proceed");
			onPanelsVisibleChange({
				isShippingDetailsPanelVisible: false,
				isAddProductsPanelVisible: true,
				isAdditionalDetailsPanelVisible: true,
			});
		}
	};

	const handleClickImage = (image_url) => {
		setState(previousState => {
			return {
				...previousState,
				productImage: image_url,
			};
		});
	};

	const handleCloseImage = () => {
		setState(previousState => {
			return {
				...previousState,
				productImage: "",
			};
		});
	};

	return (
		<Box id="add-products">
			{state.isLoading ?
				(
					<Box sx={{ width: "100%" }}>
						<LinearProgress />
					</Box>
				)
				: (
					<>
						<Box>
							<Grid container>
								<Grid item xs={12} container spacing={2} mt={0} mb={2}>
									<Grid item xs="auto" mt={1}>
										<Typography component="h3" variant="h6">
											{props.cartName}
										</Typography>
									</Grid>
									<Grid item xs />
								</Grid>
							</Grid>
						</Box>

						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="Cart table">
								<TableHead>
									<TableRow>
										<TableCell align="left">SKU</TableCell>
										{cartType === "order" && <TableCell align="left">Bundle ID</TableCell>}
										<TableCell align="left">Name</TableCell>
										<TableCell align="left">Quantity</TableCell>
										<TableCell align="left">&nbsp;</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(typeof cart === "object" && cart.length > 0) ?
										(cart.sort((b, a) => a.sku > b.sku ? -1 : 1).map((item) => {
											let itemID = item.sku;
											if (item.order_item_id) {
												itemID = item.order_item_id;
											}

											const hash = stringHash(itemID);

											return (
												<TableRow key={itemID}>
													<TableCell align="left">{item.sku}</TableCell>
													{cartType === "order" && <TableCell align="left">{item.bundle_id}</TableCell>}
													<TableCell align="left">{item.name}</TableCell>
													<TableCell align="right">
														<TextField
															id={`skuQTY-${hash}`}
															label="Quantity to add"
															name="skuQTY"
															type="number"
															size="small"
															fullWidth
															value={item.quantity}
															onChange={(e) => onQuantityChange(e, itemID)}
															InputProps={{
																inputProps: {
																	min: 1,
																}
															}}
														/>
													</TableCell>
													<TableCell align="right">
														<Button
															variant="contained"
															mb={5}
															color="destructive"
															onClick={() => {
																if (cartType === "order" && item.order_item_id) {
																	setCart(cart.filter(cartItem => cartItem.order_item_id
																		!== item.order_item_id));
																	return;
																}
																setCart(cart.filter(cartItem => cartItem.sku !== item.sku));
															}}
															aria-label={`delete ${item.sku || item.order_item_id}`}
														>
															<DeleteIcon fontSize="small" />
														</Button>
													</TableCell>
												</TableRow>
											);
										}))
										:
										(<TableRow>
											<TableCell colSpan="5" align="center">
												<AlertBox severity="error" message={cartStatus}/>
											</TableCell>
										</TableRow>)
									}
								</TableBody>
							</Table>
						</TableContainer>

						<Grid container item xs={12}>
							<Grid item xs={12}>
								<Typography component="h3" variant="h6" mt={2} mb={0}>
									Inventory <RecordsTotal total={state.inventoryData.length} />
								</Typography>
							</Grid>
							<Grid item xs />
						</Grid>

						<Grid item xs="auto" sx={{ mb: 1 }}>
							{props.cartName === "Goods In" && <FormControlLabel
								sx={{ marginLeft: 1 }}
								control={
									<Switch
										name="import_data"
										checked={state.isVisibleImportASNDiv}
										onClick={() => onClickToggleImportItemsDiv()}
									/>
								}
								label="Import ASN Data"
							/>
							}
						</Grid>

						{!state.isVisibleImportASNDiv ?
							<Grid item mt={0} mb={3}>
								<TableContainer component={Paper} sx={{ maxHeight: 600, overflow: "auto" }}>
									<Table sx={{ minWidth: 650 }} aria-label="Inventory table" id="myTable" stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell></TableCell>
												<TableCell>SKU</TableCell>
												<TableCell>Name</TableCell>
												{props.isStockAvailableColumnVisible &&
													<TableCell>Stock available</TableCell>
												}
												<TableCell align="right">
													<SearchFilter
														filter={state.filter}
														onFilterChange={(value) => 
															setState((prevState) => ({ ...prevState, filter: value }))
														}
														label="Search"
													/>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{(state.responseAlert !== "") ?
												<TableRow>
													<TableCell colSpan="5" align="center">
														<AlertBox severity="error" message={state.responseAlert}/>
													</TableCell>
												</TableRow>
												:
												(typeof state.inventoryData === "object" && state.inventoryData.length > 0) ?
													(state.inventoryData.filter(item => !item.is_bundle).map((row, index) =>
														<TableRow key={index}>
															<TableCell align="left" sx={{ width: 120 }}>
																{(row.image_url !== "") ? 
																	<Box
																		component="img"
																		className="product-image-thumbnail"
																		loading="lazy"
																		title="Enlarge image"
																		onClick={() => handleClickImage(row.image_url)}
																		src={row.image_url}
																	/>
																	:
																	null
																}
															</TableCell>
															<TableCell align="left">{row.sku}</TableCell>
															<TableCell align="left">{row.name}</TableCell>
															{props.isStockAvailableColumnVisible && <TableCell align="left">{row.stock_available}</TableCell>}
															<TableCell align="right">
																<Button
																	variant="outlined"
																	disabled={itemAddedToCart(row.sku)}
																	onClick={() => onAddItem(row.sku)}
																	color="secondary"
																>
																	Add<AddIcon fontSize="small" />
																</Button>
															</TableCell>
														</TableRow>))
													:
													<TableRow>
														<TableCell colSpan="5" align="center">
															<AlertBox severity="error" message={"No items found"}/>
														</TableCell>
													</TableRow>
											}
										</TableBody>
										<Dialog
											open={state.productImage !== ""}
											onClose={handleCloseImage}
											keepMounted
											fullWidth={true}
											maxWidth="sm"
										>
											<Box
												component="img"
												src={state.productImage}
											/>
										</Dialog>
									</Table>
								</TableContainer>
							</Grid>
							:
							<Box className={`panel${state.isVisibleImportASNDiv === true ? "-show" : "-hide"}`}>
								<ImportItems
									seller={sellerID}
									inventory={page_data.inventory}
									onASNImportData={onASNImportData}
									onASNImportStatus={onASNImportStatus}
								/>
							</Box>
						}

						{!props.hideNavButtons ?
							<Box className="align-content-right">
								<Button
									variant="outlined"
									className="back-button"
									color="secondary"
									onClick={() => handleBackButton()}
								>
									Back
								</Button>
								<Button
									variant="contained"
									ml={20}
									onClick={() => handleSubmit()}
									disabled={state.isSubmitDisabled}
								>
									Confirm
								</Button>
							</Box>
							: null}
						<SnackBarComponent />
					</>
				)
			}
		</Box>
	);
}
