import React, { useEffect } from "react";
import {
	Select,
	MenuItem,
	InputLabel,
	FormControl
} from "@mui/material";
import * as tableConstants from "../Config/TableConfig";

export default function PageRowsDropDown({
	resultsTotalCount,
	paginationPostsPerPage,
	pageRowOptions,
	onPageRowChange,
	seller,
}) {

	const pageRows = tableConstants.PAGINATION_OPTIONS;

	useEffect(() => {
		if (seller) {
			onPageRowChange(pageRows[0]);
		}
	}, [seller]);

	let updatedOptions = pageRowOptions.filter((option) => option <= resultsTotalCount);

	if (resultsTotalCount < pageRowOptions[pageRowOptions.length - 1]) {
		updatedOptions.push("All");
	}

	if (resultsTotalCount <= pageRowOptions[0]) {
		return null;
	}

	return (
		<FormControl sx={{ ml: 4, maxWidth: 100 }}>
			<InputLabel id="pageRows-select">Rows</InputLabel>
			<Select
				labelId="pageRows-select"
				id="pageRows"
				label="Rows"
				size="small"
				fullWidth
				value={paginationPostsPerPage}
				onChange={(e) => onPageRowChange(e.target.value)}
				aria-labelledby="pageRows-select"
			>
				{updatedOptions.map((item, i) => (
					<MenuItem
						key={i}
						value={item === "All" ? resultsTotalCount : item} // Set total count for "All"
					>
						{item === "All" ? "All" : item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
